<template>
  <div
    id="infobox"
    class="defaultcontent-grid--fullwidth"
  >
    <div
      v-if="info"
      class="infobox-grid"
    >
      <div class="infobox-grid--left">
        <MarkedParser
          v-if="$i18n.locale === 'nb'"
          class="markdown"
          :marked-content="info.infobox_no"
        />
        <MarkedParser
          v-else
          class="markdown"
          :marked-content="info.infobox_en"
        />
    
        <router-link
          :to="{path: '/information/about'}"
          class="square-btn--green"
        >
          <span>{{ $t('buttons.moreInfo') }}</span>
        </router-link>
      </div>
    
      <div class="infobox-grid--middle">
        <div id="infobox-address">
          <p v-if="$i18n.locale === 'en'">
            Nitja Centre for Contemporary Art
          </p>
          <p v-else>
            Nitja senter for samtidskunst
          </p>
          <p>Kirkegata 10A</p>
          <p>2000, Lillestrøm</p>
          <p v-if="$i18n.locale === 'en'">
            Norway
          </p>
          <p v-else>
            Norge
          </p>
        </div>
    
        <ul
          id="infobox-opening"
          class="timetable"
        >
          <li
            v-for="day in info.openinghours"
            :key="day.day_en"
          >
            <span v-if="$i18n.locale === 'en'">{{ day.day_en }}</span>
            <span v-else>{{ day.day_no }}</span>
            <span v-if="day.opening">{{ day.opening | formatTime }}–{{ day.closing | formatTime }}</span>
            <span v-else>{{ $t("info.closed") }}</span>
          </li>
        </ul>
      </div>
    
      <div class="infobox-grid--right">
        <a
          href="https://goo.gl/maps/nPFyKtxfPUmXUHTx9"
          target="_blank"
          rel="noreferrer noopener"
        >
          <figure id="custom-map">
            <img
              src="../../assets/images/nsfs-lillestromMap.jpg"
              class="card-hover"
              alt="nsfs-lillestromMap"
            >
          </figure>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MarkedParser from '../MarkedParser.vue'

import { format } from 'date-fns'
import gql from 'graphql-tag'

const getInfo = gql `
  query Info {
    info {
      infobox_no
      infobox_en
      openinghours {
        day_no
        day_en
        opening
        closing
      }
    }
  }
`;

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    components: {
        MarkedParser
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
    },
    mounted() {
        let wrapper = document.getElementById("markdown");
        if (wrapper) {
            let links = wrapper.getElementsByTagName('a');
            let len = links.length;
            for (let i = 0; i < len; i++) {
                links[i].target = "_blank";
                links[i].rel = "noreferrer noopener";
            }
        }
    },
    methods: {
        reverseMessage: function(mail) {
            const mailR = mail.split('').reverse().join('')
            return mailR
        },
    },
}
</script>