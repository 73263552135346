<template>
    <div v-if="posts" id="post-overview" class="defaultcontent-grid--fullwidth">
        <h1 v-if="posts.length > 0" class="section-header">
            {{ $t('headers.news') }}
            <span v-if="$route.path == '/'">
                <router-link
                :to="{ path: '/news' }"
                class="square-btn--default"
              >
              {{ $t("buttons.moreNews") }}</router-link></span>
        </h1>
        <div id="collection">
            <ListComponent :list-content="posts" />
        </div>
    </div>
</template>

<script>
import ListComponent from '../ListComponent'

export default {
    components: {
        ListComponent
    },
    props: {
        posts: {
            type: Array,
            default: () => ([])
        },
        listContent: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    }
};
</script>
