<template>
    <section id="lander-overview" class="defaultcontent-grid">
        <ExhibitionCurrent :exhibitions="exhibitions" />

        <ActivitiesList :adult_activities="adult_acts" :child_activities="child_acts" />
    
        <PostsList :posts="posts" />

        <BaseInfoBox />
    </section>
</template>

<script>
import ActivitiesList from '../components/activities/ActivitiesList'
import BaseInfoBox from '../components/base/BaseInfoBox'
import ExhibitionCurrent from '../components/exhibitions/ExhibitionCurrent'
import PostsList from '../components/posts/PostsList'

import { add } from "date-fns"
import gql from "graphql-tag"

const getExhibitions = gql `
  query Exhibitions {
    exhibitions(sort: "date_start:asc") {
      id
      slug
      title
      subtitle
      title_main_artist
      date_start
      date_end
      photo_cover {
        formats
        url
      }
    }
  }
`;
const getPosts = gql `
  query Posts {
    posts(limit: 10, sort: "manual_post_date:desc") {
      title_en
      title_no
      slug
      manual_post_date
      contentShort_no
      contentShort_en
      news_category {
        category_en
        category_no
      }
      photo_cover {
        formats
      }
    }
  }
`;
const getActivities = gql `
    query Activities($activityDay: String!) {
        adult_acts: activities(limit: -1, where: { date_gt: $activityDay, programme: "Adults"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_en
            contentShort_no
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                id
                date
                time_start
                time_end
            }
        }
        child_acts: activities(limit: -1, where: { date_gt: $activityDay, programme: "Children"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_en
            contentShort_no
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                id
                date
                time_start
                time_end
            }
        }
    }
`;

export default {
    apollo: {
        exhibitions: {
            query: getExhibitions
        },
        posts: {
            query: getPosts
        },
        child_acts: {
            query: getActivities,
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        },
        adult_acts: {
            query: getActivities,
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        }
    },
    components: {
        ExhibitionCurrent,
        PostsList,
        BaseInfoBox,
        ActivitiesList,
    },
    data: function() {
        return {
            activityDay: '',
            staticMessageEN: 'Saturday 30th of January 2021 Nitja will open its doors to the public, inaugurating the new art centre’s building with the exhibition “Above Us Only Sky”. Admission will be regulated by following health guidances. Stay tuned!',
            staticMessageNO: 'Lørdag 30. januar 2021 åpner vi dørene til vårt nye bygg, og åpningsutstillingen «Above Us Only Sky». Det vil bli begrenset innslipp grunnet pandemien. Følg med for oppdatert informasjon!',
            messageType: 'notification',
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    metaInfo() {
        return {
            title: this.$t("nav.home"),
        }
    },
    created() {
        this.getActivityDay()
    },
    methods: {
        getActivityDay() {
            let date = new Date();
            let datePlusDay = add(date, { days: -1 })
            this.activityDay = datePlusDay
        }
    },
}
</script>